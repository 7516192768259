html,body
	font-family: 'Raleway', sans-serif
	padding: 0px
	margin: 0
	font-size: 16px
	--highlight: #ff8870

	h1
		font-size: 2rem
	h2
		font-size: 1.5rem
	h3
		font-size: 1.3rem
	h4
		font-size: 1.1rem

	h1, h2, h3, h4
		margin: 0px
	p
		margin: 0px
		font-size: 1rem
.link, a
	border: none
	background: none
	color: var(--highlight)
	padding: 0px
	margin: 0px
	display: inline
	cursor: pointer
	transition: 0.15s all ease-in-out
	text-align: left
	&:hover
		opacity: 0.7
.loading
	width: calc(100vw - 80px)
	height: calc(100vh - 80px)
	display: flex
	justify-content: center
	align-items: center
.error
	height: calc(100vh - 80px)
	display: flex
	justify-content: center
	align-items: center

.btn
	text-align: center
	border-radius: 10px
	font-weight: 700
	display: block
	background-color: var(--highlight)
	color: white
	padding: 10px 12.5px
	transition: 0.15s all ease-in-out
	cursor: pointer
	user-select: none
	border: 2px solid var(--highlight)
	&:hover
		background: none
		color: var(--highlight)

.checkbox
	[type="checkbox"]
		position: relative
		left: 15px
		top: -4px
		z-index: 0
		-webkit-appearance: none
		display: none

	[type="checkbox"] + label
		user-select: none
		display: flex
		align-items: center
		@media screen and ( max-width: 480px )
			flex-direction: row-reverse

	[type="checkbox"] + label span
		width: 15px
		height: 15px
		flex-basis: 15px
		flex-shrink: 0
		border-radius: 5px
		border: 2px solid var(--highlight)
		background-color: #fff
		display: block
		content: ""
		margin-right: 5px
		z-index: 5

	[type="checkbox"]:checked+label span
		box-shadow: inset 0px 0px 0px 3px #fff
		background-color: var(--highlight)
