.widget .menu-card
	max-width: 900px
	width: 100%
	margin-left: auto
	margin-right: auto
	height: 100vh
	overflow-y: auto

	.toolbar
		display: flex
		flex-wrap: wrap
		align-items: center
		justify-content: space-between
		row-gap: 10px
		column-gap: 10px

	h1
		text-transform: uppercase
		margin-bottom: 20px
		font-weight: 600
	h2
		color: #b96554
	.sections
		padding-right: 20px
		& > div:first-of-type
			margin-top: 20px

	.wine-recommendation
		margin-top: 40px
		h3
			font-size: 1.1rem
			margin-top: 20px
			margin-left: 10px
		.article
			padding: 0px 10px
			pointer-events: none
			margin-top: 5px
	.section
		margin: 40px 0px
		&.no-title
			margin: 20px 0px

	.section-price, .min-persons, .corresponding-wines
		margin: 10px 10px
		&.section-price
			font-style: italic
		&.min-persons
			font-weight: bold
		&.corresponding-wines
			max-width: 400px
			margin-top: 20px
	.article
		display: grid
		grid-template-columns: minmax(100px, 400px) 1fr 150px
		padding: 10px 10px
		margin: 0px
		align-items: center
		border: none
		background: none
		text-align: left
		width: 100%
		&.without-price
			grid-template-columns: 1fr 0px
			&.with-number
				grid-template-columns: 35px 1fr 0px
		&.with-number
			grid-template-columns: 35px 1fr 50px 150px

		.ingredients
			ul
				margin: 0px
				list-style-type: square
				padding-left: 20px
			p, li
				font-size: 0.8rem
				font-style: italic
			.title
				margin-top: 10px
		@media screen and ( max-width: 480px )
			display: flex
			flex-direction: column
			text-align: left
			justify-content: flex-start
			align-items: flex-start
			padding: 15px 10px
			.prices
				margin-top: 10px
				font-style: italic
				.price, .amount
					font-size: 0.9rem
			&.without-price
				padding: 10px 10px
			.prices .amount
				margin-right: 10px
			.number
				margin-bottom: 5px

		&:focus
			background: #ff8870
			border-radius: 10px
			color: white
			outline: none
		.description, .manufacturer, .location
			opacity: 0.6
			font-size: 0.85rem
			margin-top: 5px
		.price-item
			display: flex
			justify-content: flex-end
			.price
				text-align: right
				flex-grow: 1
				opacity: 0.5

	&::-webkit-scrollbar-track
		box-shadow: inset 0 0 2px rgba(0,0,0,0.1)
		border-radius: 10px
		background-color: #f0f0f0

	&::-webkit-scrollbar
		width: 8px
		border-radius: 10px
		background-color: #f0f0f0

	&::-webkit-scrollbar-thumb
		border-radius: 10px
		box-shadow: inset 0 0 6px rgba(0,0,0,.1)
		background-color: #ff8870
